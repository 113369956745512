import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: "Login",
    component: () => import("../container/login/LoginPage.vue")
  }, {
    path: '/content',
    name: 'ContentPage',
    component: () => import("../container/ContentPage.vue"),
    children: [
      {
        // 模板配置 模板配置
        path: "templateConfig",
        component: () => import("../container/modelManagement/templateConfig/TemplateConfigPage.vue"),
        name: "TemplateConfigPage"
      }, {
        // 模板配置 模板组配置
        path: "templateGroup",
        component: () => import("../container/modelManagement/templateGroup/TemplateGroupPage.vue"),
        name: "TemplateGroupPage"
      }, {
        // 系统管理 角色/权限管理
        path: "roleControlment",
        component: () => import("../container/systemsManagment/roleControlment/RoleControlmentPage.vue"),
        name: "RoleControlmentPage"
      }, {
        // 系统管理 账号管理
        path: "accountControlment",
        component: () => import("../container/systemsManagment/accountControlment/AccountControlmentPage.vue"),
        name: "AccountControlmentPage"
      }, {
        // 系统管理 菜单管理
        path: "menuButtonControlment",
        component: () => import("../container/systemsManagment/menuButtonControlment/MenuButtonControlmentPage.vue"),
        name: "MenuButtonControlmentPage"
      }, {
        // 系统管理 系统参数管理
        path: "systemParameter",
        component: () => import("../container/systemsManagment/systemParameter/SystemParameterPage.vue"),
        name: "SystemParameterPage"
      }
    ],
    meta: {
      // auth: true,
      isScroll: true
    }
  }, {
    path: '/:catchAll(.*)',
    redirect: '/login'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // console.log("路由守卫：", to, from)
  let control_token = sessionStorage.getItem("camera_control_token")
  if (to.meta.auth) {
    if (control_token) {
      next()
    } else {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (to.meta.isScroll) {
    document.documentElement.scrollTop = 0
    document.documentElement.scrollLeft = 0
  }
})

export default router
